$(document).ready(function() {
    $(document).on('click', '.gen-token-button', function(e){
        e.preventDefault();
        if (confirm("Generate New API Token? \n\n This will invalidate all current API configurations.")) {
            $.ajax( {
                type: "POST",
                url: $(this).attr('data-url'),
                data: {}
            })
        } else {
            // Do nothing
        }
        return false;
    });

    $(document).on('click', '.send-test-email-button', function(e){
        e.preventDefault();

        let send_test_url = $(this).attr('data-url');
        let email = "who is specified in the Email Notifications.";
        $("td button.send-test-email-button").each(function() {
            if ($(this).attr('data-url') === send_test_url) {
                email = $(this).parent().prev().text();
                return false;// Break out of loop.
            }
        });

        if (confirm("Generate Test Email? \n\n This will send out a test email to "+email)) {
            $.ajax( {
                type: "POST",
                url: send_test_url,
                data: {}
            })
        } else {
            // Do nothing
        }
        return false;
    });

    $(document).on('click', 'button#submitCompanyForm', function (e) {
        let invalid = true;
        if (!$("#company_name").val()) {
            openErrorToast("Must have a Company Name");
            invalid = false;
        }
        if (!$("#company_company_type_id").val()) {
            openErrorToast("Must have a Company Type");
            invalid = false;
        }
        // Remove all spaces from email string
        $("#company_notification_email").val($("#company_notification_email").val().replace(/ /g, ''));
        // If email has commas, assume multiple email addresses
        let email_addresses = $("#company_notification_email").val().split(',')
        email_addresses.forEach(function (email) {
            // As you go through all emails, validate each one separately.
            if (email && !validateEmail(email)) {
                openErrorToast("Notification Email must be a valid email address");
                invalid = false;
            }
        });
        if (!invalid) {
            return false;
        }

        let form = $('#company_modal_form');
        let action = $($(this).parents("form")[0]).attr("action");
        submitModal(form, action, 'company_modal');
    });

    $(document).ajaxComplete(function(){
        if ($('.fa-clipboard').length > 0) {
            // Setup Copy to Clipboard functionality
            let clipboard = new ClipboardJS('.fa-clipboard');

            clipboard.on('success', function (e) {
                clearToastNotifications();
                openNoticeToast("API Copied to Clipboard!");
                e.clearSelection();
            });

            clipboard.on('error', function (e) {
                clearToastNotifications();
                openErrorToast("Failed to copy API to Clipboard!");
            });
        }
    });
});
