$(document).ready(function() {
    $(document).on('click', '.save-again-button', function() {
        console.log("Save Me");
        let row = $(this).closest('td').parent();
        let clonedRow = $(row).clone(false);
        let selectedCompany = $("#company").val();
        let selectedGroup = $("#connector_group_mapping").val();
        let url = $(this).siblings('input#submit_path').val();
        var d = {};
        d['authenticity_token'] = $('meta[name="csrf-token"]')[0].content;
        // get the edited object's fields and values to send to the controller
        d['admin_object'] = {}
        $(row).find('td').not('.controls').each(function () {
            let field = $(this).find('input, select').attr('id');
            d['admin_object'][field] = $(this).find('input, select').val();
        })
        clearToastNotifications();
        $.ajax({
            type: "POST",
            url: url,
            data: d,
            success: function () {
                $("select[name=admin-table_length]").change();
                let c = setTimeout(function() {
                    $("#admin-table tbody").prepend(clonedRow);
                    $("#company").val(selectedCompany);
                    $("#connector_group_mapping").val(selectedGroup);
                    $("#new_item_row input[type=text]:visible").val('');
                    clearInterval(c);
                },1000);
            },
            error: function(data){
                if (data.status === 403 || data.status === 401){
                    openForbiddenErrorToast();
                } else {
                    openErrorToast(data.responseText);
                }
            },
            complete: function() {
            }
        });
    });
});