// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import 'materialize-css/dist/js/materialize'

import 'home.js'
import 'admin.js'
import 'connector.js'
import 'companies.js'
import 'users.js'

import LocalTime from "local-time"
LocalTime.start()

import "@fortawesome/fontawesome-pro/css/all"
import "@fortawesome/fontawesome-pro/js/all"
// import '../bstable'
import "../stylesheets/application"

require('jquery')
require('jquery-toast-plugin')
require("@fortawesome/fontawesome-pro")
require("bootstrap")
// require("bstable")
// require("mdb.min")

import "chartkick/chart.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

