$(document).ready(function() {

    $(document).on('click', 'button#submitUserForm', function(e) {
        let invalid = true;
        let validPasswords = validUserPasswords($('input#user_password').val(), $('input#user_password_confirmation').val())
        if (!validPasswords) {
            invalid = false;
        }

        if (!$("#user_company_id").val()) {
            openErrorToast("Must select a company");
            invalid = false;
        }

        if (!invalid){
            e.preventDefault();
            return false;
        }
        let form = $('#user_modal_form');
        let action = $($(this).parents("form")[0]).attr("action");
        submitModal(form, action, 'user_modal');
    });

    $(document).on('click', 'button#addWhitelistIpAddress', function (e) {
        e.preventDefault();
        if ($("#new_white_list_ip").val() === "") {
            clearToastNotifications();
            openErrorToast("Must have an IP Address");
        } else {
            if (isValidIPv4($("#new_white_list_ip").val())) {
                $.ajax({
                    type: "POST",
                    url: $(this).attr('href'),
                    data: {ip_address: $("#new_white_list_ip").val()},
                    success: function (listing_id) {
                        if (listing_id) {
                            $("ul#ip_list").append(
                                "<li class=\"row\">" +
                                "<div class=\"col-sm\">" +
                                $("#new_white_list_ip").val() + "<a class=\"remove-whitelist-ip\" rel=\"nofollow\" href=\"/users/delete_api_ip_whitelist/"+$("#ip_list").attr("data-id")+"?listing_id="+listing_id+"\">" +
                                "<i class=\"far fa-trash trash-icon\"></i></a>" +
                                "</div>" +
                                "<div class=\"col-sm\">" +
                                "</div>" +
                                "</li>"
                            );
                        }
                        $("#new_white_list_ip").val('');
                        clearToastNotifications();
                        openNoticeToast('IP Address has been added');
                    },
                    error: function (data) {
                        var msg = data.responseText;
                        clearToastNotifications();
                        openErrorToast(msg);
                        $("#new_white_list_ip").val('');
                    }
                });
            } else {
                clearToastNotifications();
                openErrorToast("Invalid IP Address");
            }
        }
        return false;
    });

    $(document).on('click', 'a.remove-whitelist-ip', function(e) {
        e.preventDefault();
        if (confirm("Are you sure?")) {
            let row = $($(this).parents("li")[0]);
            $.ajax({
                type: "DELETE",
                url: $(this).attr('href'),
                success: function (data) {
                    row.remove();
                },
                error: function() {
                    clearToastNotifications();
                    openErrorToast("Unable to Delete IP Address");
                }
            });
        }
        return false;
    });

    $.fn.reset_password = function (url) {
        if (confirm('Reset User Password? \n\n This will send a password reset email to the user.')) {
            $.ajax({
                type: "post",
                url: url,
                success: function () {
                    clearToastNotifications();
                    openNoticeToast('Done!');
                },
                data: {}
            });
        }
    }

    window.isValidIPv4 = function(ip) {
        const ipv4Regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        return ipv4Regex.test(ip);
    };
});